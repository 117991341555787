<template>
    <div :style="{ height: screeHeight + 'px' }" style="background-color: #efefef">
        <van-search
            v-model="searchValue"
            show-action
            autofocus
            placeholder="搜索商品 景点"
            @search="toSearch"
            @input="onSearch"
            >
            <template #action>
                <div @click="toSearch">搜索</div>
            </template>
        </van-search>
        <van-row v-if="!isSearch" :style="{height : screeHeight - 100 +'px'}" style="background-color: #efefef;overflow: auto;">
            <van-col span="24" v-for="(item,index) in seaList" :key="index" @click="toInfo(item)" style=";background-color: #FFFFFF;">
                <p style="margin:8px">
                    <van-icon name="search" />
                    <span v-html="keyRegExp(item.name,searchValue)"></span>
                </p>
            </van-col>
        </van-row>
        <div v-if="isSearch" style="margin-left: 2%;">{{searchValue}} 的相关景点有 {{seaList.length}} 个</div>
        <myList v-if="isSearch" :list="seaList"/>
        <!-- <van-row v-if="isSearch" :style="{height : screeHeight - 100 +'px'}" style="background-color: #efefef;overflow: auto;">
            <van-col span="11" v-for="(ite,index) in seaList" :key="index"  @click="toInfo(ite)" style="margin-left: 3%;margin-top: 10px;background-color: #FFFFFF;border-radius: 12px;overflow: hidden;">
                <div>
                    <img :src="ite.img" alt="" style="width: 100%;">
                    <div>
                        <p style="margin:8px">
                            {{ite.name}}
                        </p>
                        <p style="color: #ee0a24;font-weight: bold;">
                            ￥50 起 <span style="color: #bbbbbb;font-size: 12px;font-weight: 100;text-decoration: line-through;">￥200</span>
                        </p>
                    </div>
                </div>
            </van-col>
        </van-row> -->
    </div>
</template>
<script>
import myList from '../../components/list'
// import { list } from '../../data.js'
export default {
  name: 'search',
  components:{
      myList
  },
  data(){
        return {
            searchValue:'',
            screenWidth: document.documentElement.clientWidth,     // 屏幕宽
            screeHeight: document.documentElement.clientHeight,    // 屏幕高
            list:[],
            seaList:[],
            isSearch:false
        }
    },
    created(){
    },
    mounted(){
        this.list = this.$store.state.indexList
    },
    methods:{
        toInfo(item){
            this.$router.push({path: '/info', query: item});
        },
        toSearch(){
            this.onSearch()
            this.isSearch = true
        },
        onSearch(){
            this.isSearch = false
            if(this.searchValue == ''){
                this.seaList = []
                return
            }
            this.seaList = this.list.filter(ite => ite.name.includes(this.searchValue))
        },
        keyRegExp(val, keyword) {
            val = val + '';
            if (val.indexOf(keyword) !== -1 && keyword !== '') {
                return val.replace(keyword, '<font color="#f00">' + keyword + '</font>')
            } else {
                return val
            }
        },
    }

}
</script>